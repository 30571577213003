export interface ConfigState {
    backend: {
        POSITION_SHARING: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    pollInterval: number | undefined;
    sentryToken: string | undefined;
    hereBaseUrl: string;
    hereApiKey: string;
}

const getNumberEnvValue = (envValue: string | undefined): number | undefined => {
    if (envValue === undefined) {
        return undefined;
    }
    return Number(envValue);
};

export const config: ConfigState = {
    backend: {
        POSITION_SHARING: import.meta.env.VITE_POSITION_SHARING,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    pollInterval: getNumberEnvValue(import.meta.env.VITE_POSITION_POLL_INTERVAL),
    sentryToken: undefined,
    hereBaseUrl: 'https://js.api.here.com/v3/3.1/',
    hereApiKey: import.meta.env.VITE_HERE_API_KEY,
};
